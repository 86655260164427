import React, { useEffect, useState } from 'react';
import { ErrorHandler } from 'components';
import styles from './Application.module.css';
import Navbar from './components/Navbar';
import { Header } from './components/Header';
// import { WhatsappStats } from './components/WhatsappButton';
import { UserContext, UserContextType } from 'contexts/UserContext';
import { useHistory } from 'react-router';
// import { io } from 'socket.io-client';

const Application = (props: any) => {
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { auth } = React.useContext(UserContext) as UserContextType;
  const history = useHistory();
  // const [status, setStatus] = useState<WhatsappStats>({
  //   status: 'OFFLINE',
  // });

  const handleOpenMenu = () => {
    setMobileNavOpen(true);
  };
  const handleCloseMenu = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    if (!auth) {
      history.push('/auth/login');
    }

    // if (
    //   ['ADMIN', 'ROOT'].includes(auth?.user?.role ?? '') &&
    //   process.env.NODE_ENV !== 'development'
    // ) {
    //   console.log('auth?.token', auth?.token);
    //   const socket = io(`${process.env.REACT_APP_PROD_API_URL}whatsapp`, {
    //     path: '/socket.io/',
    //     transports: ['websocket'],
    //     secure: true,
    //     // query: {
    //     //     authorization: `Bearer ${auth?.token}`,
    //     // },
    //     auth: {
    //       authorization: `Bearer ${auth?.token}`,
    //     },
    //     extraHeaders: {
    //       authorization: `Bearer ${auth?.token}`,
    //     },
    //   });
    //
    //   socket.on('connect', () => {
    //     console.log('connected', socket.id); // x8WIv7-mJelg7on_ALbx
    //     if (status.status == 'OFFLINE') {
    //       setStatus({
    //         status: 'WAITING',
    //       });
    //     }
    //   });
    //
    //   socket.on('EVENT_QR_UPDATE', (payload: any) => {
    //     console.log('EVENT_QR_UPDATE', payload);
    //     setStatus(payload);
    //   });
    //
    //   socket.on('disconnect', () => {
    //     console.log('disconnected', socket.id); // undefined
    //     setStatus({ status: 'OFFLINE' });
    //   });
    // }
  }, [auth]);

  return (
    <div className={styles.root}>
      <div className={styles.layout}>
        <div className={styles.wrapper}>
          <Navbar open={isMobileNavOpen} onClose={handleCloseMenu} />
          <div className={styles.content}>
            <Header handleOpenMenu={handleOpenMenu} />
            <ErrorHandler>{children}</ErrorHandler>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
