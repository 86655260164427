import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { UserContext, UserContextType } from 'contexts/UserContext';

function AuthGuard(props: any) {
  const { children } = props;
  const { pathname } = useLocation();

  const { auth } = React.useContext(UserContext) as UserContextType;
  if (pathname.includes('settings') && auth?.user?.role !== 'ROOT') {
    return <Redirect to="/supervision" />;
  }

  return children;
}

export default AuthGuard;
