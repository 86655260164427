import React from 'react';
import { ReactComponent as SupervisionIcon } from 'assets/Bag.svg';
import { ReactComponent as StoreIcon } from 'assets/Home.svg';
import { ReactComponent as CategoryIcon } from 'assets/Graph.svg';
import { ReactComponent as CitiesIcon } from 'assets/Location.svg';
import { ReactComponent as UsersIcon } from 'assets/Users.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as CampaignIcon } from 'assets/campaign.svg';
import { User } from 'views/auth/logic/auth_types';

interface Menu {
  title: string;
  href: string;
  roles?: User['role'][];
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const navigation_config: Menu[] = [
  // {
  //     title: 'navigation_config_command',
  //     href: '/orders'
  // },
  {
    title: 'navigation_config_supervision',
    href: '/supervision',
    icon: SupervisionIcon,
  },
  {
    title: 'navigation_config_store',
    href: '/stores',
    icon: StoreIcon,
  },
  {
    title: 'navigation_config_category',
    href: '/categories',
    icon: CategoryIcon,
  },
  {
    title: 'navigation_config_city',
    href: '/cities',
    icon: CitiesIcon,
  },
  {
    title: 'navigation_config_users',
    href: '/users',
    icon: UsersIcon,
  },
  {
    title: 'navigation_config_campaigns',
    href: '/campaigns',
    icon: CampaignIcon,
    roles: ['ROOT'],
  },
  {
    title: 'navigation_config_settings',
    href: '/settings',
    icon: SettingsIcon,
    roles: ['ROOT'],
  },
];

export default navigation_config;
