export default {
  translation: {
    'shared.french': 'Français',
    'shared.english': 'English',
    'login_page.title': 'Mboa Fashion | Login',
    'login_page.form_title': 'Login',
    'login_page.form_action_1': 'Continue',
    'login_page.form_country': 'Country',
    'login_page.form_phone': 'Phone number',
    'login_page.form_password': 'Password',
    category_add_title: 'Add Category',
    store_add_title: 'Add store',
    category_update_title: 'Detail of a category',
    city_update_title: 'Update city',
    city_add_title: 'Add city',
    city_add_name: 'Name of city',
    city_add_country: 'Country of city',
    global_dialog_add: 'Add',
    global_dialog_update: 'Edit',
    global_dialog_cancel: 'Cancel',
    navigation_config_command: 'Commands',
    navigation_config_products: 'Products',
    navigation_config_variants: 'Variants',
    navigation_config_store: 'Stores',
    navigation_config_category: 'Categories',
    navigation_config_settings: 'Settings',
    global_title_in_fr: 'Title in French',
    global_title_in_en: 'Title in English',
    category_form_parent: 'Category parent',
    store_product: '{{number}} products',
    global_search_hint: 'Search',
    global_action_open: 'Open',
    global_action_update: 'Edit',
    global_action_delete: 'Delete',
    store_update_title: 'Update Store',
    product_update_title: 'Update a product',
    global_confirm_delete: 'Confirm',
    store_name: 'Store name',
    location_loading_text: 'City, district, etc',
    publish_social_product: 'Post on social networks',
    asset_drop_zone_file_error:
      'Please select only JPEG, PNG, WEBP or Avif images',
    choice_location_button: 'Choose location',
    type_file_upload: 'Pictures (PNG, JPEG, WEBP)',
    store_banner: 'Store photo',
    browser_one_file: 'Choose one image',
    browser_multiple_file: 'Choose one or more images',
    choice_location_close: 'Close',
    choice_location_validate: 'Validate',
    store_location_button: 'Store location',
    store_location_view_button: 'View store location',
    choice_location_textfield: 'Find location',
    supervisor_name: 'First and last name of manager',
    add_store_image: 'Add store image',
    invalid_phone: 'Invalid phone number',
    global_label_cancel: 'Cancel',
    global_dialog_conf_title: 'Confirmation',
    navigation_config_filter: 'Filters',
    store_location: 'City',
    store_create_by: 'Created by {{name}}',
    store_create_at: 'On {{date}}',
    delete_category_conf: 'Are you sure you want to delete category {{name}}?',
    delete_city_conf: 'Are you sure you want to delete city {{name}}?',
    delete_variant_conf: 'Are you sure you want to delete this variant?',
    delete_product_conf: 'Are you sure you want to delete this product?',
    delete_store_conf: 'Are you sure you want to delete store {{name}}?',
    'login_page.check_whatsapp': 'Please open your whatsapp account to login.',
    'shared.internet_connexion_error':
      'An unexpected problem occurred. Please try again in a moment',
    'login_page.field_require': 'This field is required',
    global_field_require: 'This field is required',
    'login_page.phone_error': 'Invalid phone number',
    'login_page.form_subtitle':
      'Please choose your country and enter your valid WHatsApp number',
    'page_not_found.title': 'Oops, page not found!',
    'page_not_found.subtitle':
      'You are trying to access a page that does not exist or has been deleted.',
    'page_not_found.action': 'Return to home',
    page_product_title: '{{store}} | Products',
    product_add_title: 'Add Product',
    product_for_genre: 'Genre',
    product_for_generation: 'Generation',
    product_for_MEN: 'For men',
    delete_post_conf_FACEBOOK:
      'Are you sure you want to delete this post on Facebook?',
    product_for_WOMAN: 'For women',
    product_generation_CHILD: 'Child',
    product_generation_ADULT: 'Adult',
    product_generation_YOUTH: 'Young',
    product_generation_TEENAGER: 'Teenager',
    add_minimum_two_images: 'Please add at least two images of this variant',
    add_minimum_one_images: 'Please add at least one image of this variant',
    add_minimum_for_images: 'Please add at least four images of this variant',
    product_variant_images: 'Variant Pictures',
    product_variant_price: 'Sale price',
    product_variant_compare_price: 'Compare Price',
    product_variant_store_price: 'Store Price',
    product_variant_quantity: 'Quantity',
    product_variant_list: 'Variants',
    global_number_exception: 'Please enter a number',
    product_variant_update_title: 'Update Variant',
    quantity_min_1: 'Quantity cannot be less than 0',
    price_more_store_price:
      'The sale price must be higher than the store price',
    compare_price_more_price:
      'The compare price must be higher than the sale price',
    product_variant_add_title: 'Add Variant',
    product_visible_hide: 'This product will not be visible to customers',
    product_visible_show: 'This product will be visible to customers',
    product_variant_visible_hide:
      'This variant will not be visible to customers',
    product_variant_visible_show: 'This variant will be visible to customers',
    product_variant_sublist: 'Product Variants',
    add_product_image: 'Importing current product images',
    unable_import_all_images:
      'Unable to import all images. Please open product for verification.',
    create_product_success: 'Product added successfully',
    post_update_title: 'Edit post',
    select_two_asset: 'Choose images',
    post_add_title: 'Post product',
    post_add_action: 'Post',
    category_position: 'Position',
    minimum_one: 'Please enter a number greater than 0',
    publication_pending: 'Pending',
    delete_product: 'Delete this product',
    post_message: 'Post message',
    product_stats_show_zero: 'No view',
    product_stats_show_one: 'One view',
    product_stats_show_other: '{{count}} views',
    product_stats_command_zero: 'No command',
    product_stats_command_one: 'One command',
    product_stats_command_other: '{{count}} commands',
    product_stats_like_zero: 'No likes',
    product_stats_like_one: 'One like',
    product_stats_like_other: '{{count}} likes',
    product_stats_share_zero: 'No share',
    product_stats_share_one: 'One share',
    product_stats_share_other: '{{count}} shares',
    network_named_FACEBOOK: 'Facebook',
    network_named_TWITTER: 'Twitter',
    network_named_INSTAGRAM: 'Instagram',
    view_on_the_website: 'View on the website',
    view_on_the_social: 'View on',
    not_published_yet: 'Not yet published',
    update_product: 'Edit this product',
    social_last_update: 'Updated on {{date}}',
    upload_product_success: 'Product successfully updated',
    product_add_category: 'Category',
    logout_user: 'Logout',
    profile_user: 'Profile',
    product_add_brand: 'Brand',
    whatsapp_status_title: 'Whatsapp Service Status',
    whatsapp_status_title_PENDING: 'Waiting for QR code validation',
    whatsapp_status_title_CONNECTED:
      'Server connect to Whatsapp Business client',
    whatsapp_status_title_OFFLINE: 'Browser offline or server unavailable',
    whatsapp_status_title_WAITING: 'Connecting and waiting for status',
    product_search_hint: 'Search in {{store}}',
    navigation_config_users: 'Users',
    user_page_new: 'New User',
    user_page_role: 'Role',
    user_role_MANAGER: 'Manager',
    user_role_ADMIN: 'Admin',
    user_role_ALL: 'All users',
    user_role_ROOT: 'Root',
    user_genre_title: 'Genre',
    user_role_CLIENT: 'Customer',
    users_not_found: 'No found',
    users_not_found_0: 'No results found for ',
    users_not_found_1: 'Check search or try with other words',
    user_head_name: 'Name',
    user_head_surname: 'First name',
    user_search_in: 'Search in {{count}} user',
    user_head_actions: 'Actions',
    user_head_phone: 'Phone',
    user_form_MEN: 'Man',
    user_form_WOMAN: 'Woman',
    user_head_role: 'Role',
    filter_date: 'Filter by date',
    filter_date_: 'Filter by date',
    order_total: '#',
    order_detail: 'Details',
    order_status_BUILDING: 'Building',
    order_status_PENDING: 'Waiting for customer confirmation',
    order_status_ACCEPTED: 'Awaiting validation by an administrator',
    order_status_VALIDATED: 'Awaiting delivery',
    order_status_CLIENT_CANCELLED: 'Cancelled by customer',
    order_status_ADMIN_CANCELLED: 'Cancelled by an administrator',
    order_status_COMPLETED: 'Delivered',
    order_status_short: 'Status',
    order_status_short_ALL: 'All Orders',
    order_status_short_BUILDING: 'Cart building in progress',
    order_status_short_PENDING: 'Waiting for customer confirmation',
    order_status_short_ACCEPTED: 'Awaiting admin validation',
    order_status_short_VALIDATED: 'Awaiting delivery',
    order_status_short_CLIENT_CANCELLED: 'Cancelled by customer',
    order_status_short_ADMIN_CANCELLED: 'Cancelled by admin',
    order_status_short_COMPLETED: 'Delivery completed',
    order_stepper_BUILDING: 'Creating cart',
    order_stepper_BUILDING_2: "Customer's choice of products",
    order_stepper_PENDING: 'Customer confirmation',
    order_stepper_PENDING_2: 'Confirmation on whatsapp by the customer',
    order_stepper_ACCEPTED: 'Order validation',
    order_stepper_ACCEPTED_2: 'Checking available stock',
    order_stepper_VALIDATED: 'Order delivery',
    order_stepper_VALIDATED_2: 'Meet customer for delivery',
    order_stepper_VALIDATED_action_0: 'Cancel',
    order_stepper_VALIDATED_action_1: 'Confirm',
    order_stepper_CLIENT_CANCELLED: 'Cancelled by customer',
    cancel_order_title: 'Cancel by booking',
    cancel_order_action: 'Confirm Cancellation',
    order_stepper_ADMIN_CANCELLED: 'Cancel by admin',
    order_stepper_COMPLETED: 'Delivery completed',
    filter_date_start_fill: 'After {{start_date}}',
    filter_date_start: 'After',
    order_client_info: 'Customer Information',
    order_infos: 'Order Info',
    order_total_price: 'Total',
    mark_order_as_delivered: 'Mark as delivered',
    order_destination: 'Location',
    order_creator_tel: 'Whatsapp',
    order_actions_by: 'By',
    order_store: 'Store',
    order_store_place: 'Location',
    order_store_manager: 'Manager',
    order_store_manager_contact: 'Contact',
    order_actions_on: 'The',
    order_actions_where: 'at',
    global_created_at: 'Create at',
    client_register_at: 'Register on',
    client_register_name: 'Name',
    filter_date_end: 'Before',
    filter_date_day: 'This day',
    filter_date_week: 'Weekdays',
    filter_date_month: 'This month',
    filter_date_year: 'The year',
    update_order_action: 'Edit Order',
    validate_order_title: 'Validate Order',
    cancel_order_confirmation: 'Are you sure you want to cancel this order?',
    cancel_order_because: 'Reason for cancellation',
    cancel_order_because_STOCK: 'Insufficient Stock',
    cancel_order_because_DELIVERY_LOCATION: 'Delivery location unreachable',
    cancel_order_because_CLIENT_CANCEL: 'Client cancellation request',
    validate_order_delivery_location: 'Precise delivery locations',
    validate_order_delivery_date: 'Time and day of delivery',
    global_date_past_error: 'Date cannot be in the past',
    filter_date_erase: 'Erase',
    unable_to_update_profile:
      'Unable to update your profile. Please log in and try again',
    update_profile_title: 'Profile Update',
    update_password: 'Change password',
    field_require: 'This field is required',
    confirm_validate_title: 'Confirm Validate',
    complete_order_title: 'Customer Information',
    order_detail_title: 'Order Details',
    filter_date_end_fill: 'Before {{end_date}}',
    filter_date_every_fill: 'From {{start_date}} to {{end_date}}',
    navigation_config_supervision: 'Supervision',
    button_show_more: 'Show more',
    client_detail: 'Client Details',
    send_to_delivery_man: 'Send to Delivery Man',
    no_client_name: 'Anonymous Client',
    reminder_list_title: 'Reminders',
    order_detail_and_progress: 'Details and progress',
    send_message_to_client: 'Send Message',
    add_to_order: 'Add to Order',
    find_product_placeholder: 'Find a product',
    global_dialog_pin: 'Pin',
    send_variants_to_user: 'Send Variants ({{count}})',
    pin_product: 'Pin a product',
    add_reminder: 'Add Reminder',
    reminder_date: 'Reminder date',
    reminder_message: 'Message',
    no_reminder: 'No Reminder',
    reminder_cancelled_status: 'Reminder Canceled',
    reminder_sent_status: 'Reminder Sent',
    update_whatsapp_group: 'Updated whatsapp group',
    whatsapp_group_menu: 'whatsapp group',
    update_whatsapp_group_success: 'Update whatsapp group successful',
    group_name: 'Group name',
    group_link: 'Group Link',
    invalid_url: 'Please enter a valid url',
    global_copied: 'Copied',
    this_week: 'This week',
    next_week: 'Next week',
    next_month: 'Next month',
    startOf_next_month: 'Start of month',
    endOf_next_month: 'End of month',
    deliverable_on_sunday: 'Deliverable on Sunday',
    global_option_yes: 'Yes',
    global_option_no: 'No',
    delivery_option: 'Delivery option',
    delivery_option_sunday: 'Sunday delivery',
    notification_option: 'Notification settings',
    notify_store_creator: 'Notify store creator',
    global_size: 'Size',
    use_enter_to_add_item: 'Use enter to add item',
    positive_number: 'Please enter a positive number',
    update_delivery_option: 'Update delivery option',
    add_delivery_option: 'Add delivery option',
    global_city: 'City',
    delivery_price: 'Delivery price',
    copy_payment_link: 'Copy payment link',
    post_target: 'Target',
  },
};
