import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import ThemeProvider from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './routes';
import Auth from './routes/Auth';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ProgressBarStyle } from './components/ProgressBar';
import { ScrollToTop } from './components';
import 'translations';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import { UserContextProvider } from './contexts/UserContext';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: 30 * 1000,
        retry: process.env.NODE_ENV !== 'development',
      },
      mutations: {
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <SnackbarProvider
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ThemeProvider>
            <React.Fragment>
              <CssBaseline />
              <ProgressBarStyle />
              <ScrollToTop />
              <Auth>
                <Routes />
              </Auth>
            </React.Fragment>
          </ThemeProvider>
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </UserContextProvider>
    </QueryClientProvider>
  );
}
