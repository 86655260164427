export default {
  translation: {
    'shared.french': 'English',
    'shared.english': 'English',
    'login_page.title': 'Mboa Fashion | Connexion',
    'login_page.form_title': 'Connexion',
    'login_page.form_action_1': 'Continuer',
    'login_page.form_country': 'Pays',
    'login_page.form_phone': 'Numéro de téléphone',
    'login_page.form_password': 'Mot de passe',
    category_add_title: 'Ajouter une catégorie',
    city_add_title: 'Ajouter une ville',
    city_add_name: 'Nom de la ville',
    city_add_country: 'Pays de la ville',
    store_add_title: 'Ajouter une boutique',
    category_update_title: "Détail d'une catégorie",
    city_update_title: 'Modifier une ville',
    global_dialog_add: 'Ajouter',
    global_dialog_update: 'Modifier',
    global_dialog_cancel: 'Annuler',
    navigation_config_command: 'Commandes',
    navigation_config_products: 'Produits',
    navigation_config_variants: 'Variantes',
    navigation_config_store: 'Boutiques',
    navigation_config_category: 'Catégories',
    navigation_config_settings: 'Paramètres',
    global_title_in_fr: 'Titre en français',
    global_title_in_en: 'Titre en anglais',
    category_form_parent: 'Catégorie parent',
    store_product: '{{number}} produits',
    global_search_hint: 'Recherche',
    global_action_open: 'Ouvrir',
    global_action_update: 'Modifier',
    global_action_delete: 'Supprimer',
    store_update_title: 'Mettre à jour une boutique',
    product_update_title: 'Mettre à jour un produit',
    global_confirm_delete: 'Confirmer',
    store_name: 'Nom de la boutique',
    location_loading_text: 'Ville, quartier, etc',
    publish_social_product: 'Publier sur un réseau social',
    asset_drop_zone_file_error:
      'Veuillez sélectionner seulement des images JPEG, PNG, WEBP ou Avif',
    choice_location_button: 'Choisir un emplacement',
    type_file_upload: 'Images (PNG, JPEG, WEBP)',
    store_banner: 'Photo de la boutique',
    browser_one_file: 'Choisir une image',
    browser_multiple_file: 'Choisir une ou plusieurs images',
    choice_location_close: 'Fermer',
    choice_location_validate: 'Valider',
    store_location_button: 'Emplacement de la boutique',
    store_location_view_button: "Voir l'emplacement de la boutique",
    choice_location_textfield: 'Rechercher un emplacement',
    supervisor_name: 'Nom et prénom du gérant',
    add_store_image: "Ajout de l'image de la boutique",
    invalid_phone: 'Numéro de téléphone invalide',
    global_label_cancel: 'Annuler',
    global_dialog_conf_title: 'Confirmation',
    navigation_config_filter: 'Filtres',
    store_location: 'Ville',
    store_create_by: 'Créé par {{name}}',
    store_create_at: 'Le {{date}}',
    delete_category_conf:
      'Souhaiter-vous vraiment supprimer la catégorie {{name}} ?',
    delete_city_conf: 'Souhaiter-vous vraiment supprimer la ville {{name}} ?',
    delete_variant_conf: 'Souhaiter-vous vraiment supprimer cette variant ?',
    delete_product_conf: 'Souhaiter-vous vraiment supprimer ce produit ?',
    delete_store_conf:
      'Souhaiter-vous vraiment supprimer la boutique {{name}} ?',
    'login_page.check_whatsapp':
      'Veuillez ouvrir votre compte whatsapp pour vous connecter.',
    'shared.internet_connexion_error':
      'Un problème inattendu est survenu. Veuillez ressayer dans un instant',
    'login_page.field_require': 'Ce champ est requis',
    global_field_require: 'Ce champ est requis',
    'login_page.phone_error': 'Numéro de téléphone invalide',
    'login_page.form_subtitle':
      'Veuillez choisir votre pays et saisir votre numéro WHatsApp valide',
    'page_not_found.title': "Op's, page introuvable !",
    'page_not_found.subtitle':
      "Vous essayer d'accéder a une page qui n'existe pas ou qui a été supprimer.",
    'page_not_found.action': "Revenir à l'accueil",
    page_product_title: '{{store}} | Produits',
    product_add_title: 'Ajouter un produit',
    product_for_genre: 'Genre',
    product_for_generation: 'Génération',
    product_for_MEN: 'Pour homme',
    delete_post_conf_FACEBOOK:
      'Souhaitez-vous vraiment supprimer cet article sur Facebook ?',
    product_for_WOMAN: 'Pour femme',
    product_generation_CHILD: 'Enfant',
    product_generation_ADULT: 'Adulte',
    product_generation_YOUTH: 'Jeune',
    product_generation_TEENAGER: 'Adolescent',
    add_minimum_two_images:
      'Veuillez ajouter au moins deux images de cette variante',
    add_minimum_one_images:
      'Veuillez ajouter au moins une image de cette variante',
    add_minimum_for_images:
      'Veuillez ajouter au moins quatre images de cette variante',
    product_variant_images: 'Photos de la variantes',
    product_variant_price: 'Prix de vente',
    product_variant_compare_price: 'Prix de comparaison',
    product_variant_store_price: 'Prix du magasin',
    product_variant_quantity: 'Quantité',
    product_variant_list: 'Variantes',
    global_number_exception: 'Veuillez saisir un nombre',
    product_variant_update_title: 'Modifier une variante',
    quantity_min_1: 'La quantité ne peut être inférieur à 0',
    price_more_store_price:
      'Le prix de vente doit être supérieur au prix du magasin',
    compare_price_more_price:
      'Le prix de comparaison doit être supérieur au prix de vente',
    product_variant_add_title: 'Ajouter une variante',
    product_visible_hide: 'Ce produit ne sera pas visible par les clients',
    product_visible_show: 'Ce produit sera visible par les clients',
    product_variant_visible_hide:
      'Cette variante ne sera pas visible par les clients',
    product_variant_visible_show: 'Cette variante sera visible par les clients',
    product_variant_sublist: 'Différentes versions du produit',
    add_product_image: 'Importation des images du produit en cours',
    unable_import_all_images:
      "Impossible d'importer toutes les images. Veuillez ouvrir le produit pour verification.",
    create_product_success: 'Produit ajouter avec succès',
    post_update_title: 'Modifier le post',
    select_two_asset: 'Choisir les images',
    post_add_title: 'Poster sur ',
    post_add_action: 'Poster',
    category_position: 'Position',
    minimum_one: 'Veuillez saisir un nombre supérieur a 0',
    publication_pending: 'En attente',
    delete_product: 'Supprimer ce produit',
    post_message: 'Message de la publication',
    product_stats_show_zero: 'Aucune vue',
    product_stats_show_one: 'Une vue',
    product_stats_show_other: '{{count}} vues',
    product_stats_command_zero: 'Aucune commande',
    product_stats_command_one: 'Une commande',
    product_stats_command_other: '{{count}} commandes',
    product_stats_like_zero: 'Aucune like',
    product_stats_like_one: 'Une like',
    product_stats_like_other: '{{count}} likes',
    product_stats_share_zero: 'Aucune partage',
    product_stats_share_one: 'Une partage',
    product_stats_share_other: '{{count}} partages',
    network_named_FACEBOOK: 'Facebook',
    network_named_TWITTER: 'Twitter',
    network_named_INSTAGRAM: 'Instagram',
    view_on_the_website: 'Voir sur le site',
    view_on_the_social: 'Voir sur',
    not_published_yet: 'Pas encore publié',
    update_product: 'Modifier ce produit',
    social_last_update: 'Mis à jour le {{date}}',
    upload_product_success: 'Produit mis à jour avec succès',
    product_add_category: 'Catégorie',
    logout_user: 'Déconnexion',
    profile_user: 'Profile',
    product_add_brand: 'Marque',
    whatsapp_status_title: 'Status du service Whatsapp',
    whatsapp_status_title_PENDING: 'En attente de validation du code QR',
    whatsapp_status_title_CONNECTED:
      'Server connecter au client Whatsapp Business',
    whatsapp_status_title_OFFLINE:
      'Navigateur hors ligne ou serveur indisponible',
    whatsapp_status_title_WAITING: 'Connecter et en attente du status',
    product_search_hint: 'Recherche dans {{store}}',
    navigation_config_users: 'Utilisateurs',
    user_page_new: 'Nouveau utilisateur',
    user_page_role: 'Rôle',
    user_role_MANAGER: 'Manageur',
    user_role_ADMIN: 'Admin',
    user_role_ALL: 'Tout les utilisateur',
    user_role_ROOT: 'Root',
    user_genre_title: 'Genre',
    user_role_CLIENT: 'Client',
    users_not_found: 'Aucun résultat',
    users_not_found_0: 'Aucun résultat trouver pour ',
    users_not_found_1: "Verifier la recherche ou essayer avec d'autres mots",
    user_head_name: 'Nom',
    user_head_surname: 'Prénom',
    user_search_in: 'Recherche dans {{count}} utilisateur',
    user_head_actions: 'Actions',
    user_head_phone: 'Téléphone',
    user_form_MEN: 'Homme',
    user_form_WOMAN: 'Femme',
    user_head_role: 'Rôle',
    filter_date: 'Filtrer par date',
    filter_date_: 'Filtrer par date',
    order_total: '#',
    order_detail: 'Détails',
    order_status_BUILDING: 'En cours de création',
    order_status_PENDING: 'En attente de confirmation du client',
    order_status_ACCEPTED: 'En attente de validation par un administrateur',
    order_status_VALIDATED: 'En attente de livraison',
    order_status_CLIENT_CANCELLED: 'Annuler par le client',
    order_status_ADMIN_CANCELLED: 'Annuler par un administrateur',
    order_status_COMPLETED: 'Livré',
    order_status_short: 'Status',
    order_status_short_ALL: 'Toutes les commandes',
    order_status_short_BUILDING: 'Création du panier en cours',
    order_status_short_PENDING: 'Attente confirmation client',
    order_status_short_ACCEPTED: 'Attente validation admin',
    order_status_short_VALIDATED: 'Attente de livraison',
    order_status_short_CLIENT_CANCELLED: 'Annuler par le client',
    order_status_short_ADMIN_CANCELLED: 'Annuler par un admin',
    order_status_short_COMPLETED: 'Livraison effectuer',
    order_stepper_BUILDING: 'Création du panier',
    order_stepper_BUILDING_2: 'Choix des produits par le client',
    order_stepper_PENDING: 'Confirmation du client',
    order_stepper_PENDING_2: 'Confirmation sur whatsapp par le client',
    order_stepper_ACCEPTED: 'Validation de la commande',
    order_stepper_ACCEPTED_2: 'Vérification du stock disponible',
    order_stepper_VALIDATED: 'Livraison de la commande',
    order_stepper_VALIDATED_2: 'Rencontre avec le client pour la livraison',
    order_stepper_VALIDATED_action_0: 'Annuler',
    order_stepper_VALIDATED_action_1: 'Confirmer',
    order_stepper_CLIENT_CANCELLED: 'Annuler par le client',
    cancel_order_title: 'Annuler par la reservation',
    cancel_order_action: "Confirmer l'annulation",
    order_stepper_ADMIN_CANCELLED: 'Annuler par un admin',
    order_stepper_COMPLETED: 'Livraison effectué',
    filter_date_start_fill: 'Après le {{start_date}}',
    filter_date_start: 'Après le',
    order_client_info: 'Informations du client',
    order_infos: 'Informations de la commande',
    order_total_price: 'Total',
    mark_order_as_delivered: 'Marquer comme livré',
    order_destination: 'Lieu',
    order_creator_tel: 'Whatsapp',
    order_actions_by: 'Par',
    order_store: 'Boutique',
    order_store_place: 'Lieu',
    order_store_manager: 'Gérant',
    order_store_manager_contact: 'Contact',
    order_actions_on: 'Le',
    order_actions_where: 'à',
    global_created_at: 'Créer le',
    client_register_at: 'Inscrit le',
    client_register_name: 'Nom',
    filter_date_end: 'Avant le',
    filter_date_day: 'Ce jour',
    filter_date_week: 'En semaine',
    filter_date_month: 'Ce mois',
    filter_date_year: "L'année",
    update_order_action: 'Modifier la commande',
    validate_order_title: 'Valider la commande',
    cancel_order_confirmation:
      'Êtes-vous sûr de vouloir annuler cette commande ?',
    cancel_order_because: "Raison de l'annulation",
    cancel_order_because_STOCK: 'Stock insuffisant',
    cancel_order_because_DELIVERY_LOCATION: 'Lieu de livraison inaccessible',
    cancel_order_because_CLIENT_CANCEL: "Demande d'annulation du client",
    validate_order_delivery_location: 'Lieux précis de la livraison',
    validate_order_delivery_date: 'Heure et jour de la livraison',
    global_date_past_error: 'La date ne peut pas être dans le passé',
    filter_date_erase: 'Effacer',
    unable_to_update_profile:
      'Impossible de mettre à jour votre profil. Veuillez vous reconnecter et ressayer',
    update_profile_title: 'Modification du profil',
    update_password: 'Modification du mot de passe',
    field_require: 'Ce champs est requis',
    confirm_validate_title: 'Confirmer la validation',
    complete_order_title: 'Informations sur le client',
    order_detail_title: 'Détails de la commande',
    filter_date_end_fill: 'Avant le {{end_date}}',
    filter_date_every_fill: 'Du {{start_date}} au {{end_date}}',
    navigation_config_supervision: 'Supervision',
    button_show_more: 'Afficher plus',
    client_detail: 'Détails du client',
    send_to_delivery_man: 'Envoyer à un livreur',
    no_client_name: 'Client anonyme',
    reminder_list_title: 'Rappels',
    order_detail_and_progression: 'Détails et progression',
    send_message_to_client: 'Envoyer un message',
    add_to_order: 'Ajouter à la commande',
    find_product_placeholder: 'Rechercher un produit',
    global_dialog_pin: 'Épingler',
    send_variants_to_user: 'Envoyer les variantes ({{count}})',
    pin_product: 'Épingler un produit',
    add_reminder: 'Ajouter un rappel',
    reminder_date: 'Date du rappel',
    reminder_message: 'Message',
    no_reminder: 'Aucun rappel',
    reminder_cancelled_status: 'Rappel annulé',
    reminder_sent_status: 'Rappel envoyé',
    update_whatsapp_group: 'Mis à jour du groupe whatsapp',
    whatsapp_group_menu: 'Groupe whatsapp',
    update_whatsapp_group_success: 'Mis à jour du groupe whatsapp réussi',
    group_name: 'Nom du groupe',
    group_link: 'Lien du groupe',
    invalid_url: 'Veuillez entrer une url valide',
    global_copied: 'Copié',
    this_week: 'Cette semaine',
    next_week: 'Semaine prochaine',
    next_month: 'Mois prochain',
    startOf_next_month: 'Début du mois',
    endOf_next_month: 'Fin du mois',
    delivery_option: 'Option de livraison',
    delivery_option_sunday: 'Livrable le dimanche',
    notification_option: 'Paramètres de notification',
    notify_store_creator: 'Notifier le créateur de la boutique',
    post_options: 'Options de publication',
    global_size: 'Taille',
    prevent_negotiation: 'Ajouter la mention "Prix non discutable"',
    navigation_config_city: 'Villes',
    positive_number: 'Veuillez entrer un nombre positif',
    use_enter_to_add_item: 'Utiliser la touche entrée pour ajouter un élément',
    update_delivery_option: 'Modifier une option de livraison',
    add_delivery_option: 'Ajouter une option de livraison',
    global_city: 'Ville',
    delivery_price: 'Prix de livraison',
    store_cities_list: 'Villes de livraison',
    store_cities_sublist: 'Villes où on peut expédier les commandes',
    copy_payment_link: 'Copier le lien de paiement',
    post_target: 'Plateforme de publication',
    global_description_fr: 'Description (français)',
    global_description_en: 'Description (anglais)',
    product_stock: 'Gestion de stock',
    product_designation: 'Designation',
    image_search_engine_reset: 'Effacer les images',
    image_search_engine_init: 'Sélectionner les produits',
    image_search_engine: "Moteur de recherche d'images",
    global_dialog_select: 'Sélectionner',
    all_categories: 'Toutes les catégories',
    train_images: 'Initialisation du moteur de recherche',
    navigation_config_campaigns: 'Campagnes',
    campaign_add_title: 'Ajouter une campagne',
    campaign_detail_title: 'Détail de la campagne',
    create_audience_success: 'Audience créée avec succès',
    create_campaign_success: 'Campagne créée avec succès',
    delete_audience_success: 'Audience supprimée avec succès',
    stop_campaign_success: 'Campagne stoppée avec succès',
    campaign_basic_infos: 'Informations de base',
    campaign_date_infos: 'Planification',
    campaign_date_produits: 'Variantes de produits',
    campaign_products_infos: 'Produits',
    campaign_target_infos: 'Taille de la cible',
    campaign_form_name: 'Nom de la campagne',
    campaign_form_hello_message: 'Message de bienvenue',
    campaign_form_hello_message_placeholder: 'Bonjour,\nComment allez-vous ?',
    campaign_form_hello_message_helper:
      'Message à envoyer au début de la campagne',
    campaign_form_campaign_message: 'Message de la campagne',
    campaign_form_campaign_message_helper:
      'Message à envoyer après la réponse du client',
    campaign_form_campaign_message_placeholder:
      'Nous avons pensé que vous pourriez aimer ces produits',
    campaign_form_start_at: 'Début de la campagne',
    campaign_form_end_at: 'Fin de la campagne',
    campaign_target: 'Taille de la cible',
    campaign_whatsapp_audience: 'Créer ou utiliser une audience',
    campaign_wishlist: 'Utiliser une liste de souhait',
    available_message_zero: 'Aucun message restant',
    available_message_other: 'Nombre de messages restant ({{count}})',
    campaign_form_target: 'Nombre de contacts maximum',
    number_min_one: 'Veuillez entrer un nombre supérieur à 0',
    choice_wishlist_contacts: "Choisir les contacts d'une liste de souhait",
    choice_audience_contacts: "Choisir les contacts d'une audience",
    choice_audience_words: 'Mots clés {{words}}',
    choice_audience_words_label: 'Mots clés...',
    choice_variants: 'Choisir des variantes',
    add_product: 'Ajouter des produits',
    audience_name: 'Nom de l audience',
    global_details: 'Détails',
    global_stop: 'Arrêter',
    stop_campaign_conf:
      'Souhaiter-vous vraiment stopper la campagne {{name}} ?',
    choice_audience_words_helper: 'Appuyer sur entrée pour ajouter un mot clé',
    create_audience_title: 'Créer une audience',
    update_audience_title: 'Modifier une audience',
    contact_available: 'Contacts disponible: {{count}}',
    max_available_message_error: 'Limite de message dépassée',
    wishlist_contacts_zero: 'Aucun contacts',
    wishlist_contacts_one: 'Un contact',
    wishlist_contacts_other: '{{count}} contacts',
    words_or_variants_required:
      'Vous devez choisir des variantes ou des mots pour le ciblage',
    string_min_three: 'Veuillez entrer au moins 3 caractères',
    campaign_end_date_error:
      'La date de fin doit être supérieure à la date de début',
    delete_audience_conf:
      "Souhaiter-vous vraiment supprimer l'audience {{name}} ?",
    train_images_description:
      'Souhaiter-vous initialiser le moteur de recherche avec les images des produits({{count}}) sélectionnés ?',
    update_or_delete_audience: "Modifier ou supprimer l'audience",
    audience_helper:
      "Après avoir choisis une audience, si elle n'est lier a aucune campagne vous pourrez la modifier ou la supprimer.",
    image_search_engine_delete_all_image:
      'Reinitialisation du moteur de recherche',
    image_search_engine_delete_all_image_subtitle:
      "Souhaitez-vous supprimer toutes les images du moteur de recherche ? Cette action est irréversible mais n'affecte pas les images des produits",
    image_search_engine_description_zero:
      '<strong>Aucune image</strong> prise en charge',
    image_search_engine_description_one:
      '<strong>Une image</strong> prise en charge',
    image_search_engine_description:
      '<strong>{{count}} images </strong>prises en charge',
    invoice: 'Facture',
    generate: 'Générer',
    download: 'Télécharger',
  },
};
